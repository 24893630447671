import {createContext, ReactNode, useContext} from "react"

const FeaturedArticlesContext = createContext<{
  featuredArticles: {
    id: string
    slug: string | null
    title: string
    createdAt?: string
  }[]
} | null>(null);

export const FeaturedArticlesContextProvider = ({
  featuredArticles,
  children
}: {
  featuredArticles: {
    id: string
    slug: string | null
    title: string
    createdAt?: string
  }[],
  children?: ReactNode
}) => {
  return (
    <FeaturedArticlesContext.Provider
      value={{featuredArticles}}
    >
      {children}
    </FeaturedArticlesContext.Provider>
  )
}
export const useFeaturedArticlesContext = () => {
  const context = useContext(FeaturedArticlesContext)
  if (!context) throw new Error("You can only use FeaturedArticlesContext within it's provider")
  return context
}